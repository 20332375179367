export enum BookingStep {
  Unknown = 0,
  SelectPatientType = 1,
  SelectAppointmentType = 2,
  AppointmentTypeInformation = 3,
  SelectAppointmentSlot = 4,
  CompleteProfile = 5,
  SelectPatient = 6,
  AddDependant = 7,
  ConfirmPhoneNumber = 8,
  AddPatientNotes = 9,
  ConfirmBooking = 10,
  BookingSuccess = 11,
  NotAcceptingBookings = 12,
  NotAcceptingNewPatients = 13,
  PracticeOutage = 14
  // to be defined for new step
}
export enum Country {
  Australia = "AU",
  NewZealand = "NZ"
}

export enum Move {
  Backward = "Backward",
  Forward = "Forward"
}
