import { AppRoutes } from "AppRoutes";

import { ErrorBoundaryFallback } from "components/ErrorBoundaryFallback";
import { theme } from "components/theme";
import { config } from "config";
import { Environment } from "core/domain";
import ReactQueryProvider from "core/services/ReactQueryProvider";
import { StoreContextProvider } from "core/store/RootStore";
import { ErrorBoundary } from "react-error-boundary";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

import { getMsalInstance, MsalProvider } from "@bps/http-client";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

import { useWindowHeightResize } from "./components/hooks/useWindowHeightResize";
import { initAppInsights } from "./core/analytics/app-insights/app-insights.utils";
import { useAnalyticsInit } from "./core/analytics/google-analytics/google-analytics.hooks";
import { initHotJar } from "./core/analytics/hotjar/hot-jar.utils";

const appInsightPlugin = initAppInsights();
initHotJar();

function App() {
  useWindowHeightResize();
  useAnalyticsInit();

  return (
    <AppInsightsContext.Provider value={appInsightPlugin}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ReactQueryProvider>
          <StoreContextProvider>
            <MsalProvider
              instance={getMsalInstance({
                ...config.msal,
                redirectStartPage: window.location.href,
                navigateToLoginRequestUrl: true
              })}
            >
              <LocalizationProvider
                adapterLocale={config.locale}
                dateAdapter={AdapterLuxon}
              >
                <Router>
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                    <AppRoutes />
                  </ErrorBoundary>
                </Router>
              </LocalizationProvider>

              {config.environment === Environment.Dev &&
                process.env.NODE_ENV === "development" && (
                  <ReactQueryDevtools position="bottom-right" />
                )}
            </MsalProvider>
          </StoreContextProvider>
        </ReactQueryProvider>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
