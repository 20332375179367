import {
  BookingState,
  ConfirmedAppointment,
  ConfirmedReservation,
  UnconfirmedReservation
} from "core/domain/booking/BookingState";
import { StorageKey } from "core/store/Storage";
import { strings } from "core/strings";
import { guid } from "core/types";
import { DateTime } from "luxon";

type StoredReservation = Omit<
  ConfirmedReservation,
  "startTime" | "endTime" | "timeZone" | "supportStartTime" | "supportEndTime"
> & {
  startTime: string;
  endTime: string;
  supportStartTime?: string;
  supportEndTime?: string;
  timeZone: string;
};

type StoredAppointment = Omit<
  ConfirmedAppointment,
  "startTime" | "timeZone"
> & {
  startTime: string;
  timeZone: string;
};

type StoredUnconfirmedReservation = Omit<
  UnconfirmedReservation,
  "startTime" | "endTime"
> & {
  startTime: string;
  endTime: string;
};

type StoredWizardState = Omit<
  BookingState,
  | "appointmentStartTime"
  | "reservation"
  | "appointment"
  | "unconfirmedReservation"
> & {
  timeSlot?: string;
  reservation?: StoredReservation;
  appointment?: StoredAppointment;
  unconfirmedReservation?: StoredUnconfirmedReservation;
  lastUpdatedMillis: number;
};

export const bookingTimeoutMills = 15 * 60 * 1000;

const clearStateFromLocalStorage = () => {
  localStorage.removeItem(StorageKey.BHB_WIZARD_MODEL);
  return undefined;
};

const storeStateInLocalStorage = (state?: BookingState) => {
  if (state) {
    // The DateTime.toJSON() method is called when serializing to JSON, which actually calls toISO() and creates a string.
    // Note that some information is lost in the process (the locale, the zone (but not its offset) are for instance not serialized).
    // This code below stores the reservation timeZone, so we can persist this information post page refresh.
    state &&
      state.reservation &&
      (state.reservation.timeZone = state.reservation.startTime.zoneName);
    state &&
      state.unconfirmedReservation &&
      (state.unconfirmedReservation.timeZone =
        state.unconfirmedReservation.startTime.zoneName);
    state &&
      state.appointment &&
      (state.appointment.timeZone = state.appointment.startTime.zoneName);

    localStorage.setItem(
      StorageKey.BHB_WIZARD_MODEL,
      JSON.stringify({
        ...state,
        lastUpdatedMillis: new Date().valueOf()
      })
    );
  } else {
    clearStateFromLocalStorage();
  }
};

const restoreStateFromLocalStorage = (
  currentPracticeId: guid
): BookingState | undefined => {
  // Rehydrate the return parameters from local storage when the store is constructed
  const json = localStorage.getItem(StorageKey.BHB_WIZARD_MODEL);
  if (!json) return undefined;

  const {
    practiceId,
    lastUpdatedMillis,
    timeSlot: timeSlotIso,
    reservation,
    unconfirmedReservation,
    appointment,
    ...modelRest
  } = JSON.parse(json) as StoredWizardState;

  // If the practiceId stored in the state is different to the current practice, then throw out the state.
  if (!strings.areEqual(currentPracticeId, practiceId)) {
    return clearStateFromLocalStorage();
  }

  // If the booking is stale, then throw out the state.
  const now = DateTime.now();
  if (
    lastUpdatedMillis &&
    lastUpdatedMillis <= now.valueOf() - bookingTimeoutMills
  ) {
    return clearStateFromLocalStorage();
  }

  // If the time slot has expired, then throw out the state.
  const timeSlot = timeSlotIso ? DateTime.fromISO(timeSlotIso) : undefined;
  if (timeSlot && timeSlot <= now) {
    return clearStateFromLocalStorage();
  }

  const model: BookingState = { ...modelRest, practiceId };

  if (reservation) {
    const {
      startTime,
      endTime,
      timeZone,
      supportStartTime,
      supportEndTime,
      ...reservationRest
    } = reservation;

    model.reservation = {
      ...reservationRest,
      startTime: DateTime.fromISO(startTime, { zone: timeZone }), // Restore timeZone property for display purposes
      endTime: DateTime.fromISO(endTime, { zone: timeZone }), // Restore timeZone property for display purposes,
      supportStartTime: supportStartTime
        ? DateTime.fromISO(supportStartTime, { zone: timeZone })
        : undefined,
      supportEndTime: supportEndTime
        ? DateTime.fromISO(supportEndTime, { zone: timeZone })
        : undefined
    };
  }

  if (unconfirmedReservation) {
    const { startTime, endTime, timeZone, ...rest } = unconfirmedReservation;

    model.unconfirmedReservation = {
      ...rest,
      startTime: DateTime.fromISO(startTime, { zone: timeZone }), // Restore timeZone property for display purposes
      endTime: DateTime.fromISO(endTime, { zone: timeZone }) // Restore timeZone property for display purposes
    };
  }

  if (appointment) {
    const { startTime, timeZone } = appointment;

    model.appointment = {
      ...appointment,
      startTime: DateTime.fromISO(startTime, { zone: timeZone })
    };
  }

  return model;
};

export const BookingStorageService = {
  storeState: storeStateInLocalStorage,
  restoreState: restoreStateFromLocalStorage
};
