import { PropsWithChildren, ReactElement } from "react";
import { useAsync } from "react-use";

import { BrowserAuthError } from "@azure/msal-browser";
import {
  getActiveAccount,
  login,
  useMsalProgressStatus
} from "@bps/http-client";

export interface AuthenticatedProps {
  renderSpinner: (message?: string) => ReactElement;
  renderError?: (
    message: string,
    errorCode: string | undefined
  ) => ReactElement;
}

export const Authenticated = ({
  renderSpinner,
  renderError,
  children
}: PropsWithChildren<AuthenticatedProps>) => {
  const status = useMsalProgressStatus();

  const {
    loading,
    error,
    value: account
  } = useAsync(async () => {
    const account = await getActiveAccount();

    // msal is still doing its dance
    if (!!status) return undefined;
    if (account) return account;

    login({
      redirectStartPage: window.location.href
    });
    return undefined;
  }, [status]);

  const authError = error as BrowserAuthError;

  if ((!account || loading) && status) return renderSpinner(status);

  if (!loading && error && renderError)
    return renderError(
      authError?.errorMessage ?? authError.message,
      authError?.errorCode
    );
  return <>{children}</>;
};
